<template>
  <div>
    <div class="mx-2">
      <!--  v-if="columns.length > 0"  -->
      <v-select
        v-model="delete_data_info.queue"
        :options="queues"
        :placeholder="lang('t_queue')"
        label="display_name"
        :reduce="(val) => val.internal_name"
      ></v-select>
      <flat-pickr
        v-model="delete_data_info.create_date"
        class="form-control mt-1"
        :config="{
          enableTime: false,
          dateFormat: 'Y-m-d',
          allowInput: true,
          time_24hr: true,
        }"
      />
    </div>
    <div class="border-top my-1 d-flex align-items-center">
      <b-button class="ml-auto mr-1 mt-1" variant="secondary">
        {{ lang("t_cancel") }}
      </b-button>
      <b-button
        class="mt-1 mr-2"
        @click="delete_data"
        style="float: right"
        variant="danger"
        >{{ lang("t_delete") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BLink,
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    flatPickr,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
  },
  props: {
    project: "",
  },
  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    columns: [
      { internal_name: 'customer_no', display_name: globalThis._lang('t_customerNo') },
      { internal_name: 'customer_identifier', display_name: globalThis._lang('t_customerIdentifier') },
    ],
    queues: [],
    delete_data_info: {
      queue: "",
      create_date: new Date().toISOString().split("T")[0],
    },
    files: "",
  }),

  methods: {
    closeModal() {

      globalThis.crm.dynamic_modal = false;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`agent/v1/Queue`)).data;

      this.queues = response;
    },
    delete_data: async function () {
      if (
        this.delete_data_info.column == "" ||
        this.delete_data_info.data == ""
      ) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseFillTheAllNecessaryAreas"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        return;
      }

      var response = (
        await this.$http_in.delete(
          `crm/v1/DeleteData/${this.project}/${this.delete_data_info.queue}/${this.delete_data_info.create_date}`
        )
      ).data;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.closeModal();
      console.log("delete_data", response);
    },
  },

  mounted: async function () {
    await this.GetQueues();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
