<template>
  <b-row>
    <b-col class="pt-0">
      <div class="mx-2">
        <v-select
          v-model="assign_agent_info.column"
          :options="columns"
          :placeholder="lang('t_matchingColumn')"
          multiple
          label="display_name"
          :reduce="(val) => val.internal_name"
        ></v-select>
        <b-form-file
          v-model="files"
          class="mt-1 mb-2"
          @input="read_excel"
          :placeholder="lang('t_uploadFileOnClick')"
          drop-placeholder="Dosya yükle..."
          id="extension"
          accept=".xlsx, .xls"
          no-drop
        />
      </div>
      <div class="border-top my-1 d-flex align-items-center">
        <b-button class="ml-auto mr-1 mt-1" variant="secondary">
          {{ lang("t_cancel") }}
        </b-button>
        <b-button
          @click="data_management"
          style="float: right"
          class="mr-2 mt-1"
          variant="primary"
        >
          <span v-if="!is_progress">{{ lang("t_save") }}</span>
          <b-spinner small v-else />
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BLink,
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BSpinner,
  BCardFooter,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BCardFooter,
    BSpinner,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
  },
  props: {
    project: "",
  },
  methods: {
    async read_excel() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = this.$xlsx.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = this.$xlsx.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);
        this.excel.data = _data;
        this.excel.keys = Object.keys(_data[0]);
        console.log(this.excel.data);
      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    data_management: async function () {
      if (this.assign_agent_info.column.length == 0) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.is_progress = true;
      console.log("data_management");
      // if (this.assign_agent_info.column == '' || this.excel.data == []) {
      //   this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }

      // if (!this.excel.keys.includes(this.assign_agent_info.column)) {
      //   this.snackbarMessage = globalThis._lang("t_matchingColumnMustBeTheSameInTheExcelColumn");
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }
      // if (!this.excel.keys.includes('assigned_agent')) {
      //   this.snackbarMessage = `Excel datanızda assigned_agent sütunu bulunamadı.`
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }


      var response = (
        await this.$http_in.put(`crm/v1/DataManagement`, {
          collection: `${this.project}_customers`,
          assign_agent_info: this.excel.data,
          column: this.assign_agent_info.column,
        })
      ).data;

      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      // console.log("assign_agent", response);
    },
  },
  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    columns: [
      { internal_name: 'customer_no', display_name: globalThis._lang('t_customerNo') },
      { internal_name: 'customer_identifier', display_name: globalThis._lang('t_customerIdentifier') },
      { internal_name: 'Ek Alan 1', display_name: globalThis._lang('Ek Alan 1') },
    ],
    agents: [],
    excel: {
      data: [],
      keys: [],
    },
    is_progress: false,
    files: null,
    assign_agent_info: {
      column: ["customer_no"],
      data: "",
      agent: "",
    },
  }),
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
