<template>
  <div>
    <b-card no-body class="mb-0">
      <b-card-body>
        <!-- Projeler-->
        <v-select
          @input="on_project_changed"
          class="mb-3"
          :placeholder="lang('t_project')"
          v-model="currentData.project"
          label="display_name"
          :reduce="(val) => val.internal_name"
          :options="projects"
          :clearable="false"
        >
        </v-select>
        <div
          v-if="!currentData.project"
          style="position: absolute; top: 50%; left: 40%"
        >
          <b-row>
            <b-col class="text-center text-muted mt-50">
              <span>{{ "Lütfen proje seçimi yapınız" }}</span>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="currentData.project">
          <b-col cols="3">
            <h5 class="mb-1">{{ lang("t_columns") }}</h5>
            <draggable
              :list="columns"
              :group="{ name: 'g1', pull: 'clone', put: false }"
              :clone="cloneItem"
            >
              <b-badge
                class="mr-50 mb-50 cursor-move"
                v-for="(item, i) in columns"
                :key="i"
                variant="light-dark"
              >
                {{ item.display_name }}
              </b-badge>
            </draggable>
          </b-col>
          <b-col cols="9">
            <draggable
              class="dragArea list-group card mb-0"
              tag="ul"
              :list="list2"
              :group="{ name: 'g1', type: 'script' }"
              @add="changeItemId"
              style="overflow-y: auto"
            >
              <quill-editor
                v-model="currentData.content"
                :options="snowOption"
                class="border-0"
              />
            </draggable>
          </b-col>
        </b-row>
      </b-card-body>

      <b-card-footer>
        <b-button
          class="float-right ml-1"
          @click="update_script"
          variant="primary"
        >
          {{ lang("t_save") }}
        </b-button>
        <b-button @click="closeModal" class="float-right" variant="secondary">
          {{ lang("t_cancel") }}
        </b-button>
      </b-card-footer>
    </b-card>

    <!-- Kaydet Butonu -->
  </div>
</template>

<script>
import { BBadge, BCard, BCardText, BLink, BFormFile, BFormSelect, BFormCheckbox, BButton, BRow, BCol, BCardFooter, BCardBody } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import draggable from 'vuedraggable'


export default {
  components: {
    BRow, BCol, BCardBody,
    draggable,
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BCardCode,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BBadge,
    BCardFooter
  },

  data: () => ({
    lang: _l(window.localStorage.getItem('language') || "tr"),
    is_progress: false,
    projects: [],
    script: [],
    currentData: {
      project: '',
      content: '',
    },
    snowOption: {
      placeholder: globalThis._lang('t_createScript'),
      theme: 'snow'

    },
    column_labels: {
      customer_no: globalThis._lang('t_customerNo'),
      customer_name: globalThis._lang('t_customerName'),
      customer_identifier: globalThis._lang('t_customerIdentifier'),
      queue: globalThis._lang('t_queue'),
      active: globalThis._lang('t_state'),
      status: globalThis._lang('t_status'),
      assigned_agent: globalThis._lang('t_agent'),
      attempts: globalThis._lang('t_attempts'),
      total_attempts: globalThis._lang('t_totalAttempts'),
      campaign: globalThis._lang('t_campaign'),
      finish_code: globalThis._lang('t_finishCode'),
      insert_date: globalThis._lang('t_insertDate'),
      action_date: globalThis._lang('t_actionDate'),
      action_time: globalThis._lang('t_actionTime'),
      create_date: globalThis._lang('t_createDate'),
      create_time: globalThis._lang('t_createTime'),
      ex_agent: globalThis._lang('t_exAgent'),
      assign_date: globalThis._lang('t_assignDate'),
      new_assets: globalThis._lang('t_newAssets'),
      reached: globalThis._lang('t_reached'),
      reached_date: globalThis._lang('t_reachedDate'),
    },
    columns: [],
    list2: [],

  }
  ),
  methods: {
    closeModal() {

      globalThis.crm.dynamic_modal = false;
    },
    get_projects: async function () {
      var response = (
        await this.$http_in.get(`/agent/v1/Project`)
      ).data;
      this.projects = response;

    },
    update_script: async function () {
      var response = (
        await this.$http_in.post(`/crm/v1/Script`, { data: this.currentData })
      ).data;

      if (response.acknowledged) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          // text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });

        this.closeModal();
      }
    },
    get_script: async function () {
      var response = (
        await this.$http_in.get(`/crm/v1/Script`)
      ).data;

      this.script = response[0];
    },
    on_project_changed() {
      this.currentData.content = this.script.content;
      this.get_columns();
    },
    get_columns: async function () {
      this.columns = [];
      var response = (
        await this.$http_in.get(`spv/v1/ProjectColumns/${this.currentData.project}_customers`)
      ).data;
      for (const item of response[0].allkeys) {
        if (['view_moment', 'active', '_id', 'cycles', 'customer_identifier_masked', 'notes', 'phones', 'products', 'campaign', 'sms', 'extra_data', 'daily_attempts', 'update_date', 'assigned_agent2'].includes(item)) {
          continue;
        }
        this.columns.push({
          internal_name: item,
          display_name: this.column_labels[item] == undefined ? item : this.column_labels[item]
        })
      }
    },
    cloneItem(item) {
      this.currentData.content += ` {{${item.internal_name}}} `
    },

  },
  mounted: function () {
    this.get_projects();
    this.get_script();
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

