<template>
  <b-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="10000"
      :color="snackbarColor"
      class="d-none"
    >
      {{ snackbarMessage }}
      <v-btn color="black" text @click="snackbar = false"> Kapat </v-btn>
    </v-snackbar>
    <b-col class="pt-0" cols="12">
      <b-row>
        <b-col cols="12">
          <v-select
            v-model="assign_agent_info.column"
            :options="columns"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :placeholder="lang('t_matchingColumn')"
          ></v-select>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-form-file
            :placeholder="lang('t_uploadFileOnClick')"
            drop-placeholder="Dosya yükle..."
            no-drop
          />
        </b-col>
      </b-row>

      <!-- <v-text-field v-if="files == '' && assign_agent_info.column != ''" v-model="assign_agent_info.data" dense
        clearable class="fontFeatures" :label="assign_agent_info.column"
        :placeholder="`${assign_agent_info.column} 1, ${assign_agent_info.column} 2, ${assign_agent_info.column} 3 ...`"
        outlined>
      </v-text-field>

      <v-autocomplete v-model="assign_agent_info.agent" v-if="assign_agent_info.column != '' && files == ''"
        :items="agents" class="fontFeatures" label="Temsilci" outlined dense item-text="short_username"
        item-value="username">
      </v-autocomplete> -->

      <b-button @click="assign" style="float: right" class="btn-success mt-1">{{
        lang("t_upload")
      }}</b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BCard, BCardText, BLink, BRow, BFormFile, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
  },
  props: {
    project: ''
  },
  data: () => ({
    lang: _l(window.localStorage.getItem('language') || "tr"),
    columns: [
      { internal_name: 'customer_no', display_name: globalThis._lang('t_customerNo') },
      { internal_name: 'customer_identifier', display_name: globalThis._lang('t_customerIdentifier') },
    ],
    agents: [],
    excel: {
      data: [],
      keys: [],

    },
    files: '',
    assign_agent_info: {
      column: 'customer_no',
      data: '',
      agent: '',
    },
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
  }),

  methods: {
    assign() {
      this.assign_agent_info.data.length == 0 ? this.assign_agent_excel() : this.assign_agent()
    },
    async read_excel() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);
        this.excel.data = _data;
        this.excel.keys = Object.keys(_data[0]);

      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    assign_agent: async function () {

      if (this.assign_agent_info.column == '' || this.assign_agent_info.data == '' || this.assign_agent_info.agent == '') {
        this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
        this.snackbarColor = "warning";
        this.snackbar = true;
        return;
      }

      var response = (
        await this.$http.put(`/AssignAgent`,
          {
            collection: `${this.project}_customers`,
            assign_agent_info: this.assign_agent_info,
          },
          {
            headers: {
              authorization: this.$store.state.user.token,
            },
          })
      ).data;


      if (response.ok != 1) {
        this.snackbarMessage = globalThis._lang("t_thereWasAProblemWhileAssigning");
        this.snackbarColor = "error";
        this.snackbar = true;
      }
      else {
        if (response.matchedCount > 0) {
          this.snackbarMessage = `${response.matchedCount} ${globalThis._lang("t_customersHaveSuccessfullyBeenAssigned")}`;
          this.snackbarColor = "success";
          this.snackbar = true;
        }
        else {
          this.snackbarMessage = `${this.assign_agent_info.column} ${globalThis._lang("t_doesNotMatchAnyCustomers")}`;
          this.snackbarColor = "warning";
          this.snackbar = true;
        }

      }
      console.log("assign_agent", response);

    },
    assign_agent_excel: async function () {
      console.log("assign_agent_excel");
      if (this.assign_agent_info.column == '' || this.excel.data == []) {
        this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
        this.snackbarColor = "warning";
        this.snackbar = true;
        return;
      }

      if (!this.excel.keys.includes(this.assign_agent_info.column)) {
        this.snackbarMessage = globalThis._lang("t_matchingColumnMustBeTheSameInTheExcelColumn");
        this.snackbarColor = "warning";
        this.snackbar = true;
        return;
      }
      // if (!this.excel.keys.includes('assigned_agent')) {
      //   this.snackbarMessage = `Excel datanızda assigned_agent sütunu bulunamadı.`
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }

      var response = (
        await this.$http.put(`/NoteImportExcel`,
          {
            collection: `${this.project}_customers`,
            assign_agent_info: this.excel.data,
            column: this.assign_agent_info.column
          },
          {
            headers: {
              authorization: this.$store.state.user.token,
            },
          })
      ).data;


      if (response.success_count == 0) {
        this.snackbarMessage = globalThis._lang("t_AProblemOccurredwhileProcessing");
        this.snackbarColor = "error";
        this.snackbar = true;
      }
      else {
        this.snackbarMessage = `${response.success_count} ${globalThis._lang("t_customersHaveBeenSuccessfullyUpdated")}`;
        this.snackbarColor = "success";
        this.snackbar = true;
      }
      console.log("assign_agent", response);

    },
    get_chat_users: async function () {
      var response = (
        await this.$http.get(`/VsprUsers`, {
          headers: {
            authorization: this.$store.state.user.token,
          },
        })
      ).data;

      this.agents = response;
    },
    getCustomerColumns: async function () {

      var response = (
        await this.$http.get(`/CustomerColumns`, {
          headers: {
            authorization: this.$store.state.user.token,
            collection: `${this.project}_customers`,
          },
        })
      ).data;
      this.columns = response[0].allkeys.sort();
    },
  },
  computed: {
  },

  mounted: function () {
    this.getCustomerColumns();
    // this.get_chat_users();
  },

};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
