<template>
  <div>
    <div class="mx-2">
      <!--  v-if="columns.length > 0"  -->
      <v-select
        v-model="data_info.column"
        :options="columns"
        :placeholder="lang('t_matchingColumn')"
        class="mb-1"
        label="display_name"
        :reduce="(val) => val.internal_name"
      ></v-select>
      <b-form-file
        v-model="files"
        @input="read_excel"
        :placeholder="lang('t_uploadFileOnClick')"
        drop-placeholder="Dosya yükle..."
      />
    </div>
    <div class="border-top my-1 d-flex align-items-center">
      <b-button class="ml-auto mr-1 mt-1" variant="secondary">
        {{ lang("t_cancel") }}
      </b-button>
      <b-button
        class="mt-1 mr-2"
        @click="assign"
        style="float: right"
        variant="primary"
        >{{ lang("t_save") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BCardText, BLink, BRow, BFormFile, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
  },
  props: {
    project: ''
  },
  data: () => ({
    lang: _l(window.localStorage.getItem('language') || "tr"),
    columns: [
      { internal_name: 'customer_no', display_name: globalThis._lang('t_customerNo') },
      { internal_name: 'customer_identifier', display_name: globalThis._lang('t_customerIdentifier') },
    ],
    agents: [],
    excel: {
      data: [],
      keys: [],

    },
    files: '',
    data_info: {
      column: 'customer_no',
      data: '',
      agent: '',
    },
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
  }),

  methods: {
    closeModal() {

      globalThis.crm.dynamic_modal = false;
    },
    assign() {
      this.data_info.data.length == 0 ? this.assign_agent_excel() : this.assign_agent()
    },
    async read_excel() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);
        this.excel.data = _data;
        this.excel.keys = Object.keys(_data[0]);

      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    assign_agent: async function () {

      if (this.data_info.column == '' || this.data_info.data == '' || this.data_info.agent == '') {
        this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
        this.snackbarColor = "warning";
        this.snackbar = true;
        return;
      }

      var response = (
        await this.$http.put(`/AssignAgent`,
          {
            collection: `${this.project}_customers`,
            data_info: this.data_info,
          },
          {
            headers: {
              authorization: this.$store.state.user.token,
            },
          })
      ).data;


      // if (response.ok != 1) {
      //   this.snackbarMessage = globalThis._lang("t_thereWasAProblemWhileAssigning");
      //   this.snackbarColor = "error";
      //   this.snackbar = true;
      // }
      // else {
      //   if (response.matchedCount > 0) {
      //     this.snackbarMessage = `${response.matchedCount} ${globalThis._lang("t_customersHaveSuccessfullyBeenAssigned")}`;
      //     this.snackbarColor = "success";
      //     this.snackbar = true;
      //   }
      //   else {
      //     this.snackbarMessage = `${this.data_info.column} ${globalThis._lang("t_doesNotMatchAnyCustomers")}`;
      //     this.snackbarColor = "warning";
      //     this.snackbar = true;
      //   }

      // }
      console.log("assign_agent", response);

    },
    assign_agent_excel: async function () {
      console.log("assign_agent_excel");
      // if (this.data_info.column == '' || this.excel.data == []) {
      //   this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }

      // if (!this.excel.keys.includes(this.data_info.column)) {
      //   this.snackbarMessage = globalThis._lang("t_matchingColumnMustBeTheSameInTheExcelColumn");
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }
      // if (!this.excel.keys.includes('assigned_agent')) {
      //   this.snackbarMessage = `Excel datanızda assigned_agent sütunu bulunamadı.`
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }

      var response = (
        await this.$http_in.put(`crm/v1/ProductManagement`,
          {
            collection: `${this.project}_customers`,
            data: this.excel.data,
            column: this.data_info.column
          })
      ).data;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      console.log("assign_agent", response);

    },
    get_chat_users: async function () {
      var response = (
        await this.$http.get(`/VsprUsers`, {
          headers: {
            authorization: this.$store.state.user.token,
          },
        })
      ).data;

      this.agents = response;
    },
    getCustomerColumns: async function () {

      var response = (
        await this.$http.get(`/CustomerColumns`, {
          headers: {
            authorization: this.$store.state.user.token,
            collection: `${this.project}_customers`,
          },
        })
      ).data;
      this.columns = response[0].allkeys.sort();
    },
  },
  computed: {
  },

  mounted: function () {
    // this.getCustomerColumns();
    // this.get_chat_users();
  },

};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
